import React, { useEffect, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { useDispatch } from 'react-redux';
import { MotionIcon } from '../motion/MotionButton';
import { MotionTextH2 } from '../motion/MotionText';
import { MotionButton } from "../motion/MotionButton";
import { motion } from "framer-motion"
import { fetchSavingHistory, setFromSavings, setSavingsHistory } from '../../reducers/medication';
import { store } from "../../store";
import cancel from "../../assets/images/RxLink assets/new assets/regular x.svg"
import trash from "../../assets/images/RxLink assets/new assets/Trash_light.svg";
import { fetchEnrollmentList, setViewPatient } from '../../reducers/enrollmentList';
import avatar from "../../assets/images/RxLink assets/avatar icon.svg";
import Draggable from 'react-draggable';
import { useNavigate } from "react-router-dom";
import { useWindowWidth } from '../searchPharmacy/useWindowWidth';
import { deletePatient } from '../../reducers/patient';
import { successToast } from '../toast/toast';
import { Spinner } from 'react-bootstrap';




export default function Savings() {
    const dispatch = useDispatch();
    const [savingsToRender, setSavingToRender] = useState([]);

    const navigate = useNavigate();

    const [confirmModal, setConfirmModal] = useState(false);
    const [patient, setPatient] = useState({});
    const [removePatient, setRemovePatient] = useState(false);
    const [loading, setLoading] = useState(false);
    const reduxState = store.getState();
    const user = JSON.parse(localStorage.getItem("user"));

    useEffect(() => {

        if (user?.appUserSeqNo) {
            fetchEnrolllmentList();
        }

    }, [])

    useEffect(() => {
        if (removePatient && patient?.patientSeqNo) {
            setConfirmModal(false);
            let body = {
                "patientSeqNo": patient?.patientSeqNo,
                "appUserSeqNo": patient?.appUserSeqNo
            }
            dispatch(deletePatient(body)).unwrap()
                .then((response) => {
                    if (response?.status === 'OK') {
                        successToast(response?.message);
                        setRemovePatient(false);
                        setPatient({});
                        fetchEnrolllmentList();
                    }
                })
        }
    }, [removePatient, patient])

    const fetchEnrolllmentList = () => {
        let enrollmentListBody = {
            appUserSeqNo: user?.appUserSeqNo,
            domainName: "Clinical"
        };
        setLoading(true);
        dispatch(fetchEnrollmentList(enrollmentListBody))
            .unwrap()
            .then((res) => {
                setLoading(false);
                if (res?.data?.length > 0) {
                    setSavingToRender(res?.data);
                }
                else {
                    setSavingToRender([]);
                }
            })
            .catch((er) => {
                console.log(er);
                setLoading(false);
            })
            .finally(e => {
            })
    }


    const handleStart = (event, ui) => {
        console.log('Drag started:', event, ui);
        // Add your custom logic for the start of dragging here
    };

    const handleDrag = (event, ui) => {
        console.log('Dragging:', event, ui);
        // Add your custom logic for the dragging here
    };

    const handleStop = (event, ui) => {
        console.log('Drag stopped:', event, ui);
        // Add your custom logic for the end of dragging here
    };

    const viewPatient = (patient) => {
        localStorage.setItem("patientSeqNo", patient?.patientSeqNo);
        //dispatch(setViewPatient(patient));
        dispatch(setFromSavings(true))
        navigate("/medications");
    }

    const removePtient = (patient) => {
        setPatient(patient);
        setConfirmModal(true);
    }

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                        <div className="medicine_icon pharma_icon" onClick={() => {
                            dispatch(setFromSavings(true))
                            window.history.back()
                        }}>
                            <MotionIcon>
                                <i className="fas fa-angle-left"></i>
                            </MotionIcon>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                        <div className="medicine_icon medicine_icon_m">
                            <div className="">
                                <motion.img
                                    whileHover={{ scale: 1.1 }}
                                    src={avatar}
                                    className="img-fluid pointer"
                                    id="dropdownMenuButton1"
                                    aria-expanded="false"
                                    type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="medication-heading">
                            <MotionTextH2 text="Patient savings history" duration={0.03} delay={0.15} />
                        </div>
                    </div>
                </div>


                {
                    savingsToRender.length > 0 ? savingsToRender.map((el, i) => {
                        return (
                            <Draggable
                                axis="x"
                                handle=".handle"
                                defaultPosition={{ x: 0, y: 0 }}
                                position={null}
                                grid={[25, 25]}
                                scale={1}
                                onStart={handleStart}
                                onDrag={handleDrag}
                                onStop={handleStop}
                            >
                                <div className="row saving-item">
                                    <span className="col-6 fw-bold">
                                        {el.firstName} {el.lastName}
                                        {true && <br />} {true && <span style={{ fontSize: 12 }} className="fw-normal">
                                            Sent on {el.createdDateTime}
                                        </span>}
                                        {true && <br />}
                                        <span style={{ fontSize: 12 }} className="fw-normal">{el.drugs.split(",").join(', ')}</span>
                                    </span>
                                    <span className="col-5 icon-forward" style={{ paddingRight: 0 }}>
                                        {/* <MotionButton> */}
                                        <motion.button
                                            className="btn_success"
                                            style={{ minWidth: 76, maxWidth: 76, float: "right" }}
                                            onClick={() => viewPatient(el)}
                                            whileTap={{
                                                scale: 0.9,
                                                transition: { duration: 0.2 },
                                                opacity: 1,
                                                type: "tween"
                                            }}
                                        >
                                            View
                                        </motion.button>
                                        {/* </MotionButton> */}
                                    </span>
                                    <div className="col-1 autoMargin" style={{ display: "flex", justifyContent: "center" }}>
                                        <div className="text-end">
                                            <img
                                                src={trash}
                                                className=" pointer"
                                                onClick={() => {
                                                    removePtient(el)
                                                }}
                                            ></img>
                                        </div>
                                    </div>

                                </div>
                            </Draggable>
                        )
                    }) : loading ? <div style={{display: "flex", justifyContent: "center", alignItems : "center"}}><Spinner animation="border" variant="primary" /></div> : <span style={{ display: "flex", justifyContent: "center", padding: 20 }}>No savings available</span>
                }
            </div>
            <ViewSavingModal isOpen={confirmModal} confirmModal={setConfirmModal} handleClose={() => setRemovePatient(true)} />
        </>
    )
}

const ViewSavingModal = ({ isOpen, confirmModal, handleClose }) => {
    const { windowWidth } = useWindowWidth();
    return (
        <Modal show={isOpen} onHide={() => confirmModal(false)} keyboard={false} centered style={{ width: windowWidth > 991 ? "92%" : "100%", justifyContent: "center" }}>
            <Modal.Body className=""  >
                <div className='container' style={{ maxWidth: "100%", padding: 20 }}>
                    <div className="row">
                        <div className='col-10'><p style={{ fontSize: 16 }}>Delete patient savings</p></div>
                        <div className='col-2' style={{ marginTop: -7 }}>
                            <button
                                className="crossButton"
                                onClick={() => confirmModal(false)}
                                type="button"
                            >
                                <img src={cancel} />
                            </button>
                        </div>

                    </div>
                    <div className="">
                        <div className="hrDiv hrDivpadding"></div>
                    </div>
                    <div>
                        <p style={{ fontSize: 16 }}>Are you sure you want to delete this patient savings record?</p>
                    </div>
                    <div className="row">
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                            <div className="mt-20">
                                <MotionButton>
                                    <button
                                        className="btn_success"
                                        onClick={handleClose}
                                    >
                                        Continue
                                    </button>
                                </MotionButton>
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                            <div className="mt-20">
                                <MotionButton>
                                    <button
                                        type="button"
                                        className="btn_default"
                                        onClick={() => confirmModal(false)}
                                    >
                                        Cancel
                                    </button>
                                </MotionButton>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
