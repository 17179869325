import React, { useState } from "react";

const NewTabRedirector = ({
  type,
  apiEndpoint,
  apiPayload,
  tabKey,
  label = "Open in New Tab",
  style = {},
  onError = (err) => console.error(err),
  loadingMessage = "Please wait while we redirect you...",
  errorMessage = "Oops! Something went wrong. Please try again later."
}) => {
  const [openedTabs, setOpenedTabs] = useState({});

  const handleClick = () => {
    if (openedTabs[tabKey] && !openedTabs[tabKey].closed) {
      // Focus the already opened tab
      openedTabs[tabKey].focus();
    } else {
      // Open a blank tab
      const windowRef = window.open("", "_blank");

      if (!windowRef) {
        alert("Popup blocked! Please allow popups for this site.");
        return;
      }

      // Set loading placeholder with styled content
      windowRef.document.write(`
        <style>
          body { 
            margin: 0; 
            font-family: Arial, sans-serif; 
            display: flex; 
            justify-content: center; 
            align-items: center; 
            height: 100vh; 
            background-color: #f8f9fa; 
            color: #212529; 
          }
          .loading { 
            text-align: center; 
          }
          .loading h1 {
            font-size: 24px;
            margin-bottom: 10px;
          }
          .loading p {
            font-size: 16px;
          }
        </style>
        <div class="loading">
          <h1>${loadingMessage}</h1>
        </div>
      `);
      windowRef.document.title = "Redirecting...";

      // Make the API request
      apiEndpoint(apiPayload)
        .then((res) => {
          if (res?.data?.data) {
            if (type === "connective") windowRef.location = res?.data?.data?.[0]?.programs?.[0]?.imageUrl;
            else if (type === "cpd") windowRef.location = res.data.data;
            setOpenedTabs((prev) => ({ ...prev, [tabKey]: windowRef }));
          } else {
            throw new Error("Invalid response from API");
          }
        })
        .catch((err) => {
          windowRef.document.body.innerHTML = `
            <style>
              body { 
                margin: 0; 
                font-family: Arial, sans-serif; 
                display: flex; 
                justify-content: center; 
                align-items: center; 
                height: 100vh; 
                background-color: #f8d7da; 
                color: #721c24; 
              }
              .error { 
                text-align: center; 
              }
              .error h1 {
                font-size: 24px;
                margin-bottom: 10px;
              }
              .error p {
                font-size: 16px;
                margin-bottom: 20px;
              }
              .error button {
                padding: 10px 20px;
                background-color: #f5c6cb;
                border: none;
                border-radius: 5px;
                font-size: 14px;
                cursor: pointer;
              }
              .error button:hover {
                background-color: #f1b0b7;
              }
            </style>
            <div class="error">
              <h1>${errorMessage}</h1>
              <button onclick="window.close()">Close Tab</button>
            </div>
          `;
          windowRef.document.title = "Error";
          onError(err);
        });
    }
  };

  return (
    <a
      href="javascript:void(0);"
      style={{ fontSize: 11, ...style }}
      onClick={handleClick}
      aria-label={label}
    >
      {label}
    </a>
  );
};

export default NewTabRedirector;
