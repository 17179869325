import { configureStore } from "@reduxjs/toolkit";
import membersAssignAPI from "./reducers/assignMembers";
import drugsList from "./reducers/drugSearch";
import global from "./reducers/global";
import sendSMSOrEmailAPI from "./reducers/sendSMSOrEmail";
import drugPrices from "./reducers/drugPrices";
import medication from "./reducers/medication";
import drugdetails  from "./reducers/drugPrices";
import enrollmentList from "./reducers/enrollmentList";
import rxLessDetails from "./reducers/rxLessDetails";
import copayPrices from "./reducers/copayPrices";

export const store = configureStore ({
 reducer : {drugsList, global, membersAssignAPI, sendSMSOrEmailAPI, drugPrices, medication, drugdetails, enrollmentList, rxLessDetails, copayPrices}
});