import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpClient, httpClientJava, httpClientJavaPatientPortal } from "../environment";

const initialState = {
    copayPrice: [],
    prismaPrices: [],
    disclaimer: [],
  };
  
export const fetchCopayPrices = createAsyncThunk("CopayDetails", (body) =>
    httpClientJava
    .post('medication/getCopayPrices', body) 
    .then(({data}) => {
        return data;
    })
    .catch(error => error)
);

// Endpoint to fetch Prisma / Middlesex pricing and disclaimer information
export const fetchPrismaRules = createAsyncThunk("PrismaRules", (body) =>
  httpClientJavaPatientPortal
  .post('medication/getScriptingInfo', body) 
  .then(({data}) => {
      return data;
  })
  .catch(error => error)
);

export const copayDetails = createSlice({
    name: "drugdetails",
    initialState,
    reducers: {
      setCopayDetails: (state, action) => {
        state.copayPrice = action.payload;
      },
      setPrismaPrices: (state, action) => {
        state.prismaPrices = action.payload;
      },
      setDisclaimer: (state, action) => {
        state.disclaimer = action.payload;
      },
        [fetchCopayPrices.pending.type]: (state, action) => {
          state.copay = {
            status: "loading",
            data: {},
            error: {},
          };
        },
        [fetchCopayPrices.fulfilled.type]: (state, action) => {
      //console.log(action.payload);
          state.copay = {
            status: "idle",
            data: action.payload,
            error: {},
          };
        },
        [fetchCopayPrices.rejected.type]: (state, action) => {
          state.copay = {
            status: "idle",
            data: {},
            error: action.payload,
          };
        },
      },
});

export const { setCopayDetails, setPrismaPrices, setDisclaimer } = copayDetails.actions;

export default copayDetails.reducer;