import Login from "./components/auth/Login";
import SignUp from "./components/auth/Signup";
import { useEffect } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate
} from "react-router-dom";
import InsuranceInfo from "./components/insurance/InsuranceInfo";
import MedicationSearch from "./components/medications/MedicationSearch";
import Savings from "./components/savings";
import MedicationEdit from "./components/medications/MedicationEdit";
import CoupanCard from "./components/coupan/CoupanCard";
import IAmHere from "./components/coupan/IamHere";
import CoupanSend from "./components/coupan/CouponSent";
import NumberVerification from "./components/numberVerification/NumberVerification";
import CopayProgram from "./components/copayProgram/CopayProgram";
import SearchPharmacy from "./components/searchPharmacy";
import ForgotTempPassword from "./components/auth/forgotTempPassword";
import MyAccount from "./components/myAccount/MyAccount";
import { successToast, errorToast } from "./components/toast/toast";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { setResetPwdModal } from "./reducers/medication";
import { resetPassword } from "./reducers/resetPassword";
import Patient from "./components/patient/patientInfo";
import { PatientSavings } from "./components/patient/patientSavings";
import { useGetUser } from "./utilities/useGetUser";

const routes = ["/insurance", "/CopayProgram", "/FindPharmacy", "/medications", "/edit-medications", "/coupon", "/iamHere", "/couponSend", "/searchPharmacy", "/myAccount", "/savings", "/patient"];


export function RoutesWithAnimation({ auth, setAuth, ownPosition, setOwnPosition, setIp }) {
  // var userDetail = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const userDetail = useGetUser();

  const dispatch = useDispatch();
  const showResetPwdModal = useSelector((state) => state.medication.isOpenResetPasswordModal);

  const closeChangePasswordModal = () => {
    dispatch(setResetPwdModal(false))
  }

  const submitResetPassword = (submitResetPasswordData) => {
    if (submitResetPasswordData?.password) {
      let resetPwd = {
        appUserSeqNo: userDetail?.appUserSeqNo,
        username: userDetail?.username,
        password: submitResetPasswordData?.password,
        forgetPassword: submitResetPasswordData?.tempPassword ? true : false,
        tempPassword: submitResetPasswordData?.tempPassword
          ? submitResetPasswordData?.tempPassword
          : null,
      };
      if (resetPwd?.password) {
        setLoading(true);
        dispatch(resetPassword(resetPwd))
          .unwrap()
          .then((response) => {
            setLoading(false);
            if (response?.statusCode >= 200 && response?.statusCode <= 299) {
              closeChangePasswordModal()
              successToast(response?.message);
              userDetail = JSON.parse(localStorage.getItem("user"));
            } else {
              if (response?.statusCode === 401) {
                errorToast("Old password is invalid.");
              } else {
                errorToast(response?.message);
              }
            }
          })
          .catch((e) => {
            setLoading(false);
          });
      }
    }
  }

  const location = useLocation();

  useEffect(() => {
    if (!userDetail && routes.includes(location.pathname)) {
      navigate("/")
    }
  }, [location.pathname, userDetail])

  return (
    <>
      {
        loading ? (
          <div className="loader"></div>
        ) : (
          <>
            <Routes key={location.pathname} location={location}>
              <Route exact path="*" element={<Login />} />
              <Route exact path="/" element={<Login />} />
              <Route exact path="/signUp" element={<SignUp />} />
              <Route exact path="/otpVerify" element={<NumberVerification setIp={setIp} />} />
              <Route exact path="/patient" element={<Patient />} />
              <Route exact path="/insurance" element={<InsuranceInfo />} />
              <Route exact path="/CopayProgram" element={<CopayProgram />} />
              <Route exact path="/medications" element={<MedicationSearch ownPosition={ownPosition} setOwnPosition={setOwnPosition} setIp={setIp} />} />
              <Route exact path="/edit-medications" element={<MedicationEdit />} />
              <Route exact path="/coupon" element={<CoupanCard ownPosition={ownPosition} />} />
              <Route exact path="/iamHere" element={<IAmHere />} />
              <Route exact path="/couponSend" element={<CoupanSend />} />
              <Route exact path="/searchPharmacy" element={<SearchPharmacy ownPosition={ownPosition} />} />
              <Route exact path="/myAccount" element={<MyAccount />} />
              <Route exact path="/savings" element={<Savings />} />
              <Route exact path="/patientSavings" element={<PatientSavings />} />
            </Routes>

            <ForgotTempPassword
              show={showResetPwdModal}
              close={() => closeChangePasswordModal()}
              setSubmitResetPasswordData={submitResetPassword}
              isOldPassword={true}
            />
          </>
        )}
    </>
  );
}