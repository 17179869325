import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  loader: false,
  ip: '',
  ownPosition: null,
  rxSenseToken : null,
  auth: null,
  insuranceInfo: null,
  loadingPharmacy: false,
  progress: 0,
  progressLock: false,
  responseState: {
    rxLess: false,
    hippo: false,
    careCard: false,
    rxSense: false,
    prisma: false,
    copay: false,
  },
};

export const global = createSlice({
  name: "global",
  initialState,
  reducers: {
    setLoader: (state, action) => {
      console.log({ action: action.payload });
      state.loader = action.payload;
    },
    setLoadingPharmacy: (state, action) => {
      state.loadingPharmacy = action.payload;
    },
    setIP: (state, action) => {
      state.ip = action.payload;
    },
    setOwnPosition: (state, action) => {
      state.ownPosition = action.payload;
    },
    setRxSenseToken: (state, action) => {
      state.rxSenseToken = action.payload;
    },
    setAuth: (state, action) => {
      state.auth = action.payload;
    },
    setInsuranceInfo: (state, action) => {
      state.insuranceInfo = action.payload;
    },
    setProgress: (state, action) => {
      state.progress = action.payload
    },
    setProgressLock: (state, action) => {
      state.progressLock = action.payload
    },
    setResponseState: (state, action) => {
      state.responseState = action.payload
    },
    resetProgressOperation: (state, action) => {
      state.progress = 0;
      state.progressLock = false;
      state.responseState = {
        rxLess: false,
        hippo: false,
        careCard: false,
        rxSense: false,
        prisma: false,
        copay: false,
      }
    }
  }
});

// Action creators are generated for each case reducer function
export const { setLoader, setIP, setOwnPosition, setRxSenseToken, setAuth, setInsuranceInfo, setLoadingPharmacy, setProgress, setProgressLock, setResponseState, resetProgressOperation } = global.actions;

export default global.reducer;